import { useEffect, useState } from "react";

export const useDocumentFonts = (): boolean => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setIsLoaded(true);
    });
  }, []);

  return isLoaded;
};
