import { Link, useMatches } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import Heading from "../Heading";
import { BackButton, Logo, StyledNav } from "./Nav.styles";
import { ROUTES } from "~/routes/const";
import { useCart } from "@shopify/hydrogen";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "styled-components";
import { useMediaQuery } from "styled-breakpoints/use-media-query";

export const NAV_HEIGHT_DESKTOP = 76;
export const NAV_HEIGHT_MOBILE = 52;

const Nav = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const matches = useMatches();
  const { totalQuantity } = useCart();

  const hasBackButton = matches.some(
    ({ id }) => id === "routes/cart" || id === "routes/shop/$handle"
  );

  const links = [
    { name: t("New"), href: ROUTES.HOME },
    { name: t("Shop"), href: ROUTES.SHOP },
  ];

  const headingSize = useMediaQuery(theme.breakpoints.up("desktop"))
    ? "h2"
    : "h3";

  return (
    <StyledNav>
      <Logo to={ROUTES.HOME}>
        <LazyLoadImage alt="DEPT® Apparel logo" src="/logo.svg" />
      </Logo>
      {hasBackButton ? (
        <BackButton to={ROUTES.SHOP}>
          <Heading as="span" size={headingSize}>
            &larr;
          </Heading>
          <Heading as="span" hasMotion={false} size={headingSize}>
            &lt;
          </Heading>
        </BackButton>
      ) : (
        links.map(({ name, href }) => (
          <Link key={href} to={href}>
            <Heading
              as="span"
              size={headingSize}
              style={{
                // Otherwise the P in 'shop' gets cut off
                paddingRight: "1px",
              }}
            >
              {name}
            </Heading>
          </Link>
        ))
      )}
      <Link
        style={{
          gridColumn: 3,
        }}
        to={ROUTES.CART}
      >
        <Heading size={headingSize}>
          {`${t("Bag")}${
            totalQuantity > 0
              ? `.${totalQuantity < 10 ? "0" : ""}${totalQuantity}`
              : ""
          }`}
        </Heading>
      </Link>
    </StyledNav>
  );
};

export default Nav;
