import { useScrollbarWidth, useWindowSize } from "react-use";
import { Content, Line, StyledTicker } from "./Ticker.styles";
import {
  Fragment,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDocumentFonts } from "~/hooks/useDocumentFonts";

const getLineTransform = (
  lineTransform: number,
  lineTotalWidth: number
): number => {
  if (lineTransform >= lineTotalWidth) {
    return 0;
  }

  return Math.min((lineTransform += 10), lineTotalWidth);
};

export const TICKER_HEIGHT = 52;

export type TickerVariants = {
  color?: string;
  $variant?: number;
};

export type LineVariants = {
  $variant: number;
};

export interface TickerProps extends TickerVariants {
  lines: [string[], string[]];
}

const Ticker = ({ color = "orange", lines }: TickerProps) => {
  const isLoaded = useDocumentFonts();
  const scrollbarWidth = useScrollbarWidth();
  const { width } = useWindowSize();
  const lineRefs = useRef<HTMLElement[]>([]);
  const [lineTransforms, setLineTransforms] = useState<[number, number]>([
    0, 0,
  ]);

  const memoizedLines = JSON.stringify(lines);

  const { linesWithDuplicates, totalLineWidths } = useMemo(() => {
    const lines = JSON.parse(memoizedLines);

    let linesWithDuplicates: [string[][], string[][]] = [
      [[...lines[0]]],
      [[...lines[1]]],
    ];

    const totalLineWidths: [number, number] = [0, 0];

    if (
      isLoaded &&
      lineRefs.current.length > 0 &&
      typeof scrollbarWidth === "number" &&
      width
    ) {
      startTransition(() => {
        setLineTransforms([0, 0]);
      });

      const containerWidth = width - scrollbarWidth;

      lineRefs.current.forEach((ref, index) => {
        const lineWidth = ref.offsetWidth;
        const amountOfLinesNeeded = Math.ceil(containerWidth / lineWidth);

        linesWithDuplicates[index] = [...Array(amountOfLinesNeeded).keys()].map(
          () => [...lines[index]]
        );

        totalLineWidths[index] = lineWidth * amountOfLinesNeeded;
      });
    }

    return {
      linesWithDuplicates,
      totalLineWidths,
    };
  }, [isLoaded, memoizedLines, scrollbarWidth, width]);

  useEffect(() => {
    let line1LastTime = 0;
    let line2LastTime = 0;
    let rafId = 0;

    const playAnimation = (time: number) => {
      const line1Delta = time - line1LastTime;
      const line2Delta = time - line2LastTime;

      if (line1Delta > 600 && totalLineWidths[0] > 0) {
        startTransition(() => {
          setLineTransforms((prevLineTransforms) => [
            getLineTransform(prevLineTransforms[0], totalLineWidths[0]),
            prevLineTransforms[1],
          ]);
        });

        line1LastTime = time;
      }

      if (line2Delta > 400 && totalLineWidths[1] > 0) {
        startTransition(() => {
          setLineTransforms((prevLineTransforms) => [
            prevLineTransforms[0],
            getLineTransform(prevLineTransforms[1], totalLineWidths[1]),
          ]);
        });

        line2LastTime = time;
      }

      rafId = requestAnimationFrame(playAnimation);
    };

    rafId = requestAnimationFrame(playAnimation);

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [totalLineWidths]);

  return (
    <StyledTicker color={color}>
      {linesWithDuplicates.map((lines, index) => (
        <Line
          key={index}
          style={{
            transform: `translate3d(-${lineTransforms[index]}px, 0, 0)`,
          }}
          $variant={(index + 1) as LineVariants["$variant"]}
        >
          {lines.map((line, contentIndex) => (
            <Fragment key={contentIndex}>
              <Content
                ref={(ref) => {
                  if (contentIndex === 0 && ref) {
                    lineRefs.current[index] = ref;
                  }
                }}
                size="label-val"
              >
                {line.map((text) => (
                  <span key={text}>{text}</span>
                ))}
              </Content>
              <Content size="label-val">
                {line.map((text) => (
                  <span key={text}>{text}</span>
                ))}
              </Content>
            </Fragment>
          ))}
        </Line>
      ))}
    </StyledTicker>
  );
};

export default Ticker;
