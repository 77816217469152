import { Link } from "@remix-run/react";
import { styled } from "styled-components";
import { layoutPadding } from "~/styles/mixins";

export const StyledNav = styled.nav`
  ${layoutPadding};
  align-items: center;
  display: grid;
  gap: 0 ${({ theme }) => theme.space[36]};
  grid-template-columns: auto 1fr auto;
  margin-bottom: ${({ theme }) => theme.space[16]};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    gap: 0 ${({ theme }) => theme.space[192]};
  }
`;

export const Logo = styled(Link)`
  display: block;
  height: auto;
  left: ${({ theme }) => theme.space[56]};
  position: absolute;
  top: -${({ theme }) => theme.space[40]};
  width: 70px;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    left: ${({ theme }) => theme.space[64]};
  }
`;

export const BackButton = styled(Link)`
  position: fixed;
  left: 16px;
  top: 62px;
  z-index: 100;

  > span:last-of-type {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
  }

  &:hover {
    > span {
      &:first-of-type {
        visibility: hidden;
      }

      &:last-of-type {
        display: block;
      }
    }
  }
`;
