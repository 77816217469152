import Text from "~/components/Text";
import styled, { css } from "styled-components";

export const StyledTicker = styled.div`
  left: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  margin-bottom: ${({ theme }) => theme.space[8]};
  padding-top: ${({ theme }) => theme.space[16]};

  ${({ color, theme }) =>
    color === "orange" &&
    css`
      color: ${theme.colors.orange};
    `};

  ${({ color, theme }) =>
    color === "white" &&
    css`
      color: ${theme.colors.white};
    `};
`;

interface LineProps {
  $variant: number;
}

export const Line = styled.div<LineProps>`
  display: flex;

  ${({ theme, $variant }) =>
    $variant === 1 &&
    css`
      margin-bottom: ${theme.space[4]};

      span {
        margin-right: 90px;

        ${theme.breakpoints.up("desktop")} {
          margin-right: 124px;
        }
      }
    `}

  ${({ theme, $variant }) =>
    $variant === 2 &&
    css`
      span {
        margin-right: 210px;

        ${theme.breakpoints.up("desktop")} {
          margin-right: 360px;
        }
      }
    `}
`;

export const Content = styled(Text)`
  flex: 0 0 auto;
`;
